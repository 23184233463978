<template>
  <v-main>
    <v-container>
      <h3 class="mb-5 mt-5">Edit ServiceDirectory ID : {{ serviceDirectory.id }}</h3>
      <section v-if="errored">
        <p>
          We're sorry, we're not able to retrieve this information at the
          moment, please try back later
        </p>
      </section>
      <v-btn @click="goBackToSDirectoryList">Back to Service Directory List</v-btn>
      <div v-if="loading">Loading...</div>
      <v-text-field v-model="serviceDirectory.name" placeholder="Add Title">
        <template v-slot:label> Name</template>
      </v-text-field>
      <v-text-field v-model="serviceDirectory.organization" placeholder="Organization"
        ><template v-slot:label> Organization </template></v-text-field
      >
      <v-text-field v-model="serviceDirectory.state_region" placeholder="State Region"
        ><template v-slot:label> State Region</template></v-text-field
      >
      <v-text-field v-model="serviceDirectory.contact_numbers" placeholder="Contact Numbers"
        ><template v-slot:label> Contact Numbers</template></v-text-field
      >
      <v-text-field v-model="serviceDirectory.opening_days" placeholder=" Opening Days"
        ><template v-slot:label>Opening Days</template></v-text-field
      >
      <v-text-field v-model="serviceDirectory.opening_hours" placeholder=" Opening Hours"
        ><template v-slot:label>Opening Hours</template></v-text-field
      >
      <v-textarea v-model="serviceDirectory.address">
        <template v-slot:label>
          <div>Contents</div>
        </template>
      </v-textarea>
      <v-btn @click="goBackToSDirectoryList">Back To Service Directory List</v-btn>|<v-btn
        @click="updateServiceDirectory"
        >Update</v-btn
      >
    </v-container>
  </v-main>
</template>

<script>
// @ is an alias to /src
// import { mapGetters } from "vuex";
import { GET_SERVICE_DIRECTORY, UPDATE_SERVICE_DIRECTORY } from "../store/actions.type";

export default {
  name: "EditServiceDirectory",
  data() {
    return {
      serviceDirectory: {
        id: this.$route.params.serviceDirectoryId,
        name: null,
        organization: "",
        state_region: "",
        township: "",
        address: null,
        contact_numbers: null,
        opening_days: "",
        opening_hours: 1,
        created_at: null,
        updated_at: null,
      },
      loading: false,
      errored: false,
    };
  },
  created() {
    this.getServiceDirectory();
  },
  methods: {
    goBackToSDirectoryList() {
      this.$router.push({
        name: "ServiceDirectory",
      });
    },
    getServiceDirectory(){
    this.$store
      .dispatch(GET_SERVICE_DIRECTORY, this.$route.params.serviceDirectoryId )
      .then((data) => {
        this.loading = false;
        this.serviceDirectory = data;
      })
      .catch((response) => {
        console.log(response);
        this.loading = false;
        this.errored = true;
      })
      .finally(() => (this.loading = false));
    },
    updateServiceDirectory() {
      if (this.serviceDirectory.address != "" && this.serviceDirectory.name != "") {
        this.$store
          .dispatch(UPDATE_SERVICE_DIRECTORY, { serviceDirectoryData: this.serviceDirectory })
          .then((data) => {
            this.loading = false;
            this.serviceDirectory = data;
            this.$router.push({
              name: "ServiceDirectory",
            });
          })
          .catch((response) => {
            console.log(response);
            this.loading = false;
            this.errored = true;
          })
          .finally(() => (this.loading = false));
      }
    },
  },
};
</script>
